import request from "@/utils/request";
const controller = "/user";
// const controller2 = "/Expert";


export function loginUser(data:any) {
  return request({
    url: `${controller}/loginUser`,
    method: "post",
    data
  });
}

export function login(data:any) {
  return request({
    url: `${controller}/createUser`,
    method: "post",
    data
  });
}
export function deleteAsync(data:any) {
  return request({
    url: `${controller}/DeleteAsync`,
    method: "post",
    params:data
  });
}




